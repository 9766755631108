import dayjs from 'dayjs';
import type { ConsumptionAPIError, ConsumptionAPIResponse } from '~/app-modules/explore/types';
import type { StandardTypes } from '~/app-modules/standard/types';
import type { TooltipData } from '~/components/nrjx/chart/tooltip/index.vue';
import type { EnergyTypes } from '~/types/new/energy';

export const useConsumptionModal = () => {
  // Composables
  const { t } = useI18n();
  const { client } = useApiV2();
  const { formatNumberToIsoNumber } = useNumbers();
  const { getFormattedDateFromAggregateLevel } = useChart();

  // Data
  const abortController = ref<AbortController | null>(null);
  const requestId = ref<string | null>(null);
  const loading = ref(true);
  const response = ref<ConsumptionAPIResponse | null>(null);
  const error = ref<ConsumptionAPIError | null>(null);
  const analysisType = {
    production: t('global.production'),
    fabrication_order: t('global.fabrication_order'),
    weekly: t('global.weekly_consumption'),
    off_production: t('global.off_production_consumption'),
  };

  // Methods
  const title = (options: { type: StandardTypes; start: string; end: string }) => {
    switch (options.type) {
      case 'production':
        return t('global.production_from');
      case 'fabrication_order':
        return t('global.fabrication_order_from');
      case 'weekly':
        return t('global.weekly_consumption_from');
      case 'off_production':
        return t('global.off_production_consumption_from');
      default:
        return '';
    }
  };

  const getConsumption = async (start: string, end: string, areaId: number, energyType: EnergyTypes) => {
    const requestIdValue = dayjs().toString();
    requestId.value = requestIdValue;

    if (abortController.value) {
      abortController.value.abort();
    }

    try {
      abortController.value = new AbortController();
      loading.value = true;

      const reponse = await client.GET('/v2/areas/{area_id}/explore/consumptions', {
        params: {
          query: {
            energy_type: energyType,
            start: dayjs(start).format('YYYY-MM-DD HH:mm'),
            end: dayjs(end).format('YYYY-MM-DD HH:mm'),
            aggregation_level: 'hour',
          },
          path: {
            area_id: areaId,
          },
        },
        signal: abortController.value?.signal,
      });

      if (reponse.data?.data) {
        response.value = reponse.data.data;
      } else {
        error.value = reponse.data.error;
        response.value = null;
      }
      loading.value = false;
    } catch (error) {
      if (error.name !== 'AbortError') {
        response.value = null;
        error.value = {
          message: error.message,
          status_code: 500,
          error_code: 'no_response',
        };
        loading.value = false;
        throw error;
      }
    }
  };

  // Computed
  const options = computed(() => {
    return {
      x: {
        ticks: {
          callback: (value) => {
            return dayjs(response.value?.items[value].start).format('DD/MM/YYYY HH:mm');
          },
        },
      },
      tooltip: (context) => {
        const data = response.value?.items[context[0].index];
        const tooltipData: TooltipData = {
          title: t('gloabl.power'),
          subtitle: `${formatNumberToIsoNumber(data.avg_power)} kW`,
          body: [
            { title: t('global.consumption'), value: `${formatNumberToIsoNumber(Number(data.consumption.quantity))} kWh` },
            { title: t('global.cost'), value: `${formatNumberToIsoNumber(Number(data.consumption.cost))} €` },
          ],
          footer: getFormattedDateFromAggregateLevel(data.start, 'hour'),
        };
        return tooltipData;
      },
    };
  });
  const data = computed(() => {
    return {
      labels: response.value?.items.map((item) => dayjs(item.start).toDate()) ?? [],
      datasets: [{ data: response.value?.items.map((item) => item.avg_power) ?? [], label: 'Consommation', color: '#1BA774' }],
    };
  });

  return { title, getConsumption, analysisType, options, data, loading, error, response };
};
