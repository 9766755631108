<script setup lang="ts">
import dayjs from 'dayjs';
import type { EnergyTypes } from '~/types/new/energy';

// Types
export type ConsumptionModalProps = {
  type?: 'weekly' | 'off_production' | 'production' | 'fabrication_order';
  start?: string;
  end?: string;
  areaId?: number;
  energyType?: EnergyTypes;
  fabricationOrderId?: string;
  quantity?: number;
  consumptionPerUnit?: number;
};

// Props
const open = defineModel<boolean>();
const props = defineProps<ConsumptionModalProps>();

// Composables
const { formatNumberToIsoNumber } = useNumbers();
const { title, options, loading, data, getConsumption, response, error } = useConsumptionModal();

// Stores
const { getArea } = areaStore();

// Computed
const area = computed(() => getArea(props.areaId));
const formatedTitle = computed(() => title({ type: props.type, start: props.start, end: props.end }));

const unit = computed(() => {
  if (props.type === 'fabrication_order') {
    return area.value?.fabrication_order_unit?.symbol ?? '--';
  } else if (props.type === 'production') {
    return area.value?.production_unit?.symbol ?? '--';
  }
  return area.value?.production_unit?.symbol ?? '--';
});

watch(
  () => open.value,
  () => {
    if (open.value) {
      let start = '';
      let end = '';

      if (props.type === 'fabrication_order' || props.type === 'off_production') {
        start = dayjs(props.start).format('YYYY-MM-DD HH:mm');
        end = dayjs(props.end).format('YYYY-MM-DD HH:mm');
      } else {
        start = dayjs(props.start).startOf('day').format('YYYY-MM-DD HH:mm');
        end = dayjs(props.end).startOf('day').format('YYYY-MM-DD HH:mm');
      }
      getConsumption(start, end, props.areaId, props.energyType);
    } else {
      response.value = null;
    }
  },
  { immediate: true, deep: true },
);
</script>
<template>
  <nrjx-modal v-model="open" width="1000">
    <template #header>
      <div class="flex items-center gap-2">
        <p class="text-md font-semibold text-gray-900">{{ formatedTitle }}</p>
        <ui-badge color="primary" class="!text-md">
          {{
            dayjs(start).format(`DD/MM/YYYY ${type === 'fabrication_order' || type === 'off_production' ? $t('global.at') + ' HH:mm' : ''}`)
          }}
        </ui-badge>
        <p v-if="!dayjs(start).isSame(dayjs(end), 'day')" class="text-sm lowercase text-gray-900">{{ $t('global.to_date') }}</p>
        <ui-badge v-if="!dayjs(start).isSame(dayjs(end), 'day')" color="primary" class="!text-md">
          {{
            dayjs(end).format(`DD/MM/YYYY ${type === 'fabrication_order' || type === 'off_production' ? $t('global.at') + ' HH:mm' : ''}`)
          }}
        </ui-badge>
      </div>
    </template>
    <div class="grid grid-cols-3 gap-2">
      <!-- Analysis type -->
      <div class="border border-gray-200 rounded px-2 pr-4 h-[32px] flex items-center justify-between">
        <div class="flex items-center gap-2">
          <ui-badge color="gray">
            {{ area?.name }}
          </ui-badge>
        </div>
        <p class="text-xs text-gray-500">{{ $t('global.zone_and_analysis') }}</p>
      </div>

      <!-- Quantity -->
      <div v-if="quantity" class="border border-gray-200 rounded px-2 pr-4 h-[32px] flex items-center justify-between">
        <p class="text-xs text-gray-900">{{ formatNumberToIsoNumber(quantity) }} {{ unit }}</p>
        <p class="text-xs text-gray-500">{{ $t('global.quantity') }}</p>
      </div>

      <!-- Cost -->
      <div class="border border-gray-200 rounded px-2 pr-4 h-[32px] flex items-center justify-between">
        <p v-if="!loading" class="text-xs text-gray-900">{{ formatNumberToIsoNumber(Number(response?.total_consumption.cost ?? 0)) }} €</p>
        <ui-skeleton-loader v-else class="w-10 h-4 rounded-full" />
        <p class="text-xs text-gray-500">{{ $t('global.cost') }}</p>
      </div>

      <!-- EPI -->
      <div v-if="consumptionPerUnit !== null" class="border border-gray-200 rounded px-2 pr-4 h-[32px] flex items-center justify-between">
        <p class="text-xs text-gray-900">{{ formatNumberToIsoNumber(consumptionPerUnit, 2) }} kWh/{{ unit }}</p>
        <p class="text-xs text-gray-500">{{ $t('global.epi') }}</p>
      </div>

      <!-- Consumption -->
      <div class="border border-gray-200 rounded px-2 pr-4 h-[32px] flex items-center justify-between">
        <p v-if="!loading" class="text-xs text-gray-900">
          {{ formatNumberToIsoNumber(Number(response?.total_consumption.quantity ?? 0)) }} kWh
        </p>
        <ui-skeleton-loader v-else class="w-10 h-4 rounded-full" />
        <p class="text-xs text-gray-500">{{ $t('global.consumption') }}</p>
      </div>

      <!-- Average power -->
      <div class="border border-gray-200 rounded px-2 pr-4 h-[32px] flex items-center justify-between">
        <p v-if="!loading" class="text-xs text-gray-900">{{ formatNumberToIsoNumber(response?.avg_power ?? 0) }} kW</p>
        <ui-skeleton-loader v-else class="w-10 h-4 rounded-full" />
        <p class="text-xs text-gray-500">{{ $t('global.average_power') }}</p>
      </div>
    </div>
    <div class="mt-4 border border-gray-200 rounded p-4">
      <nrjx-chart-line :loading="loading" :data="data" :error="error" :options="options" disabled-zoom title="kW">
        <template #no-data>
          <div v-if="error" class="flex flex-col items-center justify-center h-full">
            <div class="flex flex-col items-center justify-center border border-orange-500 bg-orange-100 rounded-md p-4">
              <ui-icon name="TriangleAlert" class="text-orange-500 mb-4" />
              <p class="text-orange-500 text-sm">{{ $t(`error.${error?.error_code}`) }}</p>
            </div>
          </div>
        </template>
      </nrjx-chart-line>
    </div>
  </nrjx-modal>
</template>
